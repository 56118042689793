var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isVariablesField)?_c('VariablesField',{attrs:{"field":_vm.field,"field-wrapper":true,"show-toggle":true},on:{"toggle-variables-field":_vm.toggleVariablesField},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_c('FieldWrapper',{attrs:{"field":_vm.field,"variables-field-active":_vm.variablesFieldActive},on:{"toggle-variables-field":_vm.toggleVariablesField},scopedSlots:_vm._u([{key:"label-append",fn:function(){return [_c('b-form-checkbox',{attrs:{"switch":""},on:{"change":() =>
            _vm.directInput === true
              ? _vm.setDirectInputValue()
              : _vm.updateValue(_vm.directInputValue)},model:{value:(_vm.directInput),callback:function ($$v) {_vm.directInput=$$v},expression:"directInput"}},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("formHelper.directInput")))])])]},proxy:true},{key:"default",fn:function(){return [(_vm.directInput)?_c('div',[_c('Code',{attrs:{"field":_vm.directInputJsonField},on:{"input":_vm.updateValue},model:{value:(_vm.directInputValue),callback:function ($$v) {_vm.directInputValue=$$v},expression:"directInputValue"}})],1):_c('div',[_vm._l((_vm.collection),function(entry,i){return _c('div',{key:i,class:[
            i === _vm.collection.length - 1 ? 'mb-3' : 'mb-1',
            _vm.stackFields ? 'py-3 px-5 bg-light rounded' : ''
          ]},[_c('div',{staticClass:"row align-items-center my-0 px-2",class:[]},[_vm._l((_vm.field.fields),function(entry,key){return _c('div',{key:key,staticClass:"py-0 px-1",class:[_vm.stackFields ? 'col-12 mb-1' : 'col-5']},[_c('JsonField',{ref:"jsonField",refInFor:true,attrs:{"name":entry.name,"default-value":_vm.collection[i][entry.name],"disabled":_vm.field.returnJson &&
                  entry.name === _vm.valueField &&
                  !_vm.collection[i][_vm.keyField]},on:{"input":val => _vm.updateCollection(val, i, entry.name)}})],1)}),(!_vm.stackFields)?_c('div',{staticClass:"col-2 py-0 text-right"},[_c('i',{staticClass:"fal fa-xmark icon-lg py-2 px-1 ml-n3",class:{ 'cursor-pointer text-hover-primary': !_vm.isDisabled },on:{"click":function($event){return _vm.removeField(i)}}})]):_c('div',{staticClass:"col-12 p-0 mb-n2 text-center"},[_c('div',{staticClass:"btn btn-sm btn-text-primary btn-hover-light-primary",on:{"click":function($event){return _vm.removeField(i)}}},[_vm._v(" "+_vm._s(_vm.$t("formHelper.remove"))+" ")])])],2)])}),(_vm.showAddField)?_c('div',{staticClass:"btn btn-link text-muted p-0",class:[
            _vm.isDisabled ? 'disabled' : 'cursor-pointer text-hover-primary'
          ],on:{"click":_vm.addField}},[_c('i',{staticClass:"fal fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("formHelper.jsonAddField"))+" ")]):_vm._e()],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }