<template>
  <div>
    <InputField
      ref="inputText"
      v-model="value"
      type="text"
      :field="fakeField"
    />
  </div>
</template>

<script>
import InputField from "@/components/Tools/FormHelper/Components/Input";
export default {
  components: { InputField },
  props: {
    defaultValue: {
      type: null,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    value: {
      get: function () {
        return this.defaultValue;
      },
      set: function (value) {
        this.$emit("input", value);
      }
    },
    fakeField: function () {
      return {
        enableVariables: true,
        placeholder: this.name,
        type: "text",
        disabled: this.disabled,
        enableTypecast: true
      };
    }
  },
  mounted() {},
  methods: {}
};
</script>
