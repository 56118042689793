<template>
  <div>
    <!------------ START: Variables field ------------>
    <VariablesField
      v-if="isVariablesField"
      v-model="value"
      :field="field"
      :field-wrapper="true"
      :show-toggle="true"
      @toggle-variables-field="toggleVariablesField"
    />
    <!------------ END: Variables field ------------>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper
      v-else
      :field="field"
      :variables-field-active="variablesFieldActive"
      @toggle-variables-field="toggleVariablesField"
    >
      <!------------ START: Direct input switch ------------>
      <template #label-append>
        <b-form-checkbox
          v-model="directInput"
          switch
          @change="
            () =>
              directInput === true
                ? setDirectInputValue()
                : updateValue(directInputValue)
          "
        >
          <span class="text-muted">{{ $t("formHelper.directInput") }}</span>
        </b-form-checkbox>
      </template>
      <!------------ END: Direct input switch ------------>
      <!------------ START: Slot default ------------>
      <template #default>
        <!------------ START: Direct input ------------>
        <div v-if="directInput">
          <Code
            v-model="directInputValue"
            :field="directInputJsonField"
            @input="updateValue"
          />
        </div>
        <!------------ END: Direct input ------------>
        <!------------ START: Field input ------------>
        <div v-else>
          <!------------ START: Json entry rows ------------>
          <div
            v-for="(entry, i) in collection"
            :key="i"
            :class="[
              i === collection.length - 1 ? 'mb-3' : 'mb-1',
              stackFields ? 'py-3 px-5 bg-light rounded' : ''
            ]"
          >
            <div class="row align-items-center my-0 px-2" :class="[]">
              <!------------ START: Fields ------------>
              <div
                v-for="(entry, key) in field.fields"
                :key="key"
                class="py-0 px-1"
                :class="[stackFields ? 'col-12 mb-1' : 'col-5']"
              >
                <JsonField
                  ref="jsonField"
                  :name="entry.name"
                  :default-value="collection[i][entry.name]"
                  :disabled="
                    field.returnJson &&
                    entry.name === valueField &&
                    !collection[i][keyField]
                  "
                  @input="val => updateCollection(val, i, entry.name)"
                />
              </div>
              <!------------ END: Fields ------------>
              <!------------ START: Remove button ------------>
              <div v-if="!stackFields" class="col-2 py-0 text-right">
                <i
                  class="fal fa-xmark icon-lg py-2 px-1 ml-n3"
                  :class="{ 'cursor-pointer text-hover-primary': !isDisabled }"
                  @click="removeField(i)"
                />
              </div>
              <div v-else class="col-12 p-0 mb-n2 text-center">
                <div
                  class="btn btn-sm btn-text-primary btn-hover-light-primary"
                  @click="removeField(i)"
                >
                  {{ $t("formHelper.remove") }}
                </div>
              </div>
              <!------------ END: Remove button ------------>
            </div>
          </div>
          <!------------ END: Json entry rows ------------>
          <!------------ START: Add field button ------------>
          <div
            v-if="showAddField"
            class="btn btn-link text-muted p-0"
            :class="[
              isDisabled ? 'disabled' : 'cursor-pointer text-hover-primary'
            ]"
            @click="addField"
          >
            <i class="fal fa-plus" />
            {{ $t("formHelper.jsonAddField") }}
          </div>
          <!------------ END: Add field button ------------>
        </div>
        <!------------ END: Field input ------------>
      </template>
      <!------------ END: Slot default ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import {
  base,
  eagerValidation,
  variablesField
} from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import { getValidations } from "@/components/Tools/FormHelper/Helper/functions";
import _ from "lodash";
import JsonField from "@/components/Tools/FormHelper/Components/JsonField";
import VariablesField from "@/components/Tools/FormHelper/Components/InputVariables";
import Code from "@/components/Tools/FormHelper/Fields/Code.vue";
import { isJson } from "@/components/Tools/helperFunctions";

export default {
  components: { FieldWrapper, JsonField, VariablesField, Code },
  mixins: [base, variablesField, eagerValidation],
  props: {},
  data() {
    return {
      // Direct input toggle
      directInput: false,
      directInputValue: "",
      // Always validate json, merge with given validators
      fieldValidations: Object.assign(
        { json: true },
        this.field.validations ?? {}
      ),
      // Show variables dropdown
      showVariables: false,
      // Get focused field
      focusedField: {
        // Entries key of focused field
        entry: 0,
        // Key of field in entry
        key: "key",
        // HTML Element to attach dropdown to
        el: undefined
      },
      // Field definitions for FormHelper code field
      directInputJsonField: {
        lang: "json",
        plain: true
      }
    };
  },
  validations() {
    return {
      value: getValidations(
        this.fieldValidations,
        this.enableVariables,
        this.field.type
      )
    };
  },
  computed: {
    value: {
      get: function () {
        // Return inherited value
        return this.defaultValue;
      },
      set: function (value) {
        // Trigger input event with new value
        this.onInput(this.parseJsonStrings(value));
      }
    },
    // Computed getter/setter for collection
    collection: {
      get: function () {
        // Get current value or empty object as fallback
        let value =
          typeof this.value === "object" && this.value !== null
            ? this.value
            : [];
        let collection = value;
        // If value is not an array, get entries from Object
        if (!Array.isArray(value)) {
          collection = [];
          Object.keys(value).forEach(key => {
            let entry = {};
            entry[this.field.fields[0].name] = key;
            entry[this.field.fields[1].name] =
              key === "" ? "" : this.value[key];
            collection.push(entry);
          });
        }
        // Return collection
        return collection.map(entry => {
          Object.keys(entry).forEach(key => {
            // If value of entry is of type object
            if (typeof entry[key] === "object" && entry[key] !== null) {
              // JSON stringify value to prevent displaying "[object Object]"
              entry[key] = JSON.stringify(entry[key]);
            }
          });
          return entry;
        });
      },
      set: function (value) {
        // Set new value
        this.value = value;
        // Trigger validation
        this.$v.value.$touch();
      }
    },
    stackFields: function () {
      // Check if more than to fields are set per row to stack fields
      return this.field.fields
        ? this.field.fields.length > 2
        : this.collection.length
        ? Object.keys(this.collection[0]).length > 2
        : false;
    },
    keyField: function () {
      if (!this.field.returnJson) {
        return "";
      }
      return this.field.fields[0].name;
    },
    valueField: function () {
      if (!this.field.returnJson) {
        return "";
      }
      return this.field.fields[1].name;
    },
    showAddField: function () {
      return (
        this.collection.length === 0 ||
        Object.values(this.collection[this.collection.length - 1] ?? {}).some(
          val => !!val
        )
      );
    }
  },
  mounted() {},
  methods: {
    // Add field row
    addField() {
      if (this.isDisabled) {
        return;
      }
      let entry = {};
      // Check if fields structure is set
      if (this.field.fields) {
        // Create new entry by fields
        this.field.fields.forEach(field => (entry[field.name] = ""));
      } else {
        // Create entry with key/value
        entry = { key: "", value: "" };
      }
      this.onChange(entry, String(this.collection.length));
      // Add entry
      let collection = this.collection;
      collection.push(entry);
      // Update value
      this.collection = collection;
    },
    // Remove entry from value
    removeField(index) {
      if (this.isDisabled) {
        return;
      }
      this.onChange(this.collection[index], String(index), true);
      // Remove entry from collection
      let collection = this.collection;
      collection.splice(index, 1);
      this.collection = collection;
    },
    // Update value
    updateCollection(value, index, key) {
      let collection = this.collection;

      let inCollection = collection.some(
        (entry, i) => entry.name === value && key === "name" && i !== index
      );

      let fieldToValidate = this.$refs.jsonField.filter(
        field => field.$options.propsData.name === "name"
      )[index];
      // Use of $refs gives us the opportunity to set a variable in here and make use of them in the mixins.js
      if (inCollection) {
        fieldToValidate?.$refs.inputText &&
          (fieldToValidate.$refs.inputText.manualError = true);
      } else {
        this.onChange(value, index + "." + key);
        collection[index][key] = value;
        this.collection = [...collection];
        fieldToValidate?.$refs.inputText &&
          (fieldToValidate.$refs.inputText.manualError = false);
      }
    },
    parseJsonStrings(obj) {
      for (let key in obj) {
        if (isJson(obj[key]) && typeof obj[key] === "string") {
          obj[key] = JSON.parse(obj[key]);
        } else if (typeof obj[key] === "object") {
          // Recursively parse JSON strings in nested objects
          obj[key] = this.parseJsonStrings(obj[key]);
        }
      }

      return obj;
    },
    setVariable(variable) {
      // Set value
      this.collection[this.focusedField.entry][this.focusedField.key] =
        variable;
    },
    // Override onInput function from mixin
    onInput(value) {
      if (this.field.returnJson && !this.isVariablesField) {
        value = Object.fromEntries(
          value.map(entry => {
            return Object.values(entry);
          })
        );
      }
      this.$emit("input", value);
    },
    onChange(value, path = "", remove = false) {
      // Emit change event
      const changeEventPayload = {
        name: this.field.name,
        value: value,
        valuePath: path,
        remove: remove,
        old: path ? _.get(this.collection, path) : _.cloneDeep(this.value)
      };
      this.$emit("change", changeEventPayload);
      // Execute onChange function if set
      if (typeof this.field.onChange === "function") {
        this.field.onChange();
      }
    },
    toggleVariablesField() {
      this.directInput = false;
      let status = !this.isVariablesField;
      this.isVariablesField = status;
      // Set value to either empty string (if variables component gets activated)
      // or field's default value (if variables component gets deactivated)
      this.value = status ? "" : [];
    },
    setDirectInputValue() {
      let value = null;

      if (this.field.returnJson) {
        value = JSON.stringify(this.value, null, 2);
      } else {
        value = JSON.stringify(
          Object.fromEntries(
            this.value.map(entry => {
              return Object.values(entry);
            })
          ),
          null,
          2
        );
      }

      return (this.directInputValue = value);
    },
    updateValue(value) {
      try {
        // Try to parse given string to json
        let parsed = JSON.parse(value);
        // Map json to collection
        let collection = [];
        Object.keys(parsed).forEach(key => {
          let entry = {};
          entry[this.field.fields[0].name] = key;
          entry[this.field.fields[1].name] =
            key === ""
              ? ""
              : typeof parsed[key] === "object"
              ? JSON.stringify(parsed[key])
              : parsed[key];
          collection.push(entry);
        });
        this.$v.value.$model = collection;
      } catch (e) {
        // If it fails, set string as model
        this.$v.value.$model = [];
      }
      // Trigger validation
      this.$v.value.$touch();
    }
  }
};
</script>
